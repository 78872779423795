import styled, {css} from "styled-components";
import {globalstyles} from "../../../../../../styles/animations/globalstyles";


export const PlanDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1.5rem;
    width: 100%;
    border: 1px solid ${globalstyles.colors.lightGray};
    border-radius: 8px;
    
    @media (min-width: ${globalstyles.sizes.lg}) {
      font-size: 19px;
      flex-direction: row;
    }
`

interface PlanImageProps {
    backgroundImage: string
}
export const PlanImage = styled.div<PlanImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 16/9;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  border-radius: 19px;
  color: white;
  font-size: 16px;
  font-weight: 800;

  @media (min-width: ${globalstyles.sizes.lg}) {
    font-size: 19px;
    width: 5rem;
    aspect-ratio: 1;
  }
`

export const PlanDetailsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
`

export const PlanInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
  
    @media (min-width: ${globalstyles.sizes.lg}) {
      flex-direction: row;
      align-items: center;
      margin: 0;
    }
`

export const PlanLink = styled.button`
    appearance: none;
    background-color: transparent;
    border-color: transparent;
    color: ${globalstyles.colors.gray};
    font-size: 11px;
    font-weight: 500;
    margin: .5rem 0 0;
    text-align: center;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
`
export const PlanSelection = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 11px;
    font-weight: 600;
`

export const AdditionalToolHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

export const AdditionalToolDescription = styled.div`
    flex-grow: 1;
  
    & ul {
      line-height: 1rem;
      font-size: .7rem;
    }
  
`
export const AdditionalToolFooter = styled.div`
  @media (min-width: ${globalstyles.sizes.lg}) {
      display: flex;
      justify-content: center;
      flex-basis: 10rem;
  }
`

export const AdditionalToolRangeContainer = styled.div`
  display: flex;
  padding: 0 2rem;
  margin: 1rem 0 1rem;
`

interface AdditionalToolRangeDotMarkProps {
    isCurrent: boolean
}

export const AdditionalToolRangeDotMark = styled.div<AdditionalToolRangeDotMarkProps>`
  ${(props) => props.isCurrent && css`
    position: relative;
    
    &:hover::before {
      content: "Plan actual";
      position: absolute;
      height: 1.3rem;
      top: -2rem;
      left: 0;
      right: 0;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 11rem;
      font-size: 13px;
      font-weight: 500;
      background-color: white;
      white-space: nowrap;
      border-radius: 10px;
      box-shadow: ${globalstyles.boxShadows.centered};
    }
  `}
`

interface AdditionalToolCollapsibleContentProps {
    active: boolean
}
export const AdditionalToolCollapsibleContent = styled.div<AdditionalToolCollapsibleContentProps>`
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: margin-top 200ms ease-in;
  
  ${(props) => props.active && css`
    height: auto;
    margin-top: 1rem;
  `}
`
export const AdditionalToolFixedContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
  
    @media (min-width: ${globalstyles.sizes.lg}) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
`

export const AdditionalRangeLegendContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 1.5rem;
`

export const AdditionalToolContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1.5rem;
    width: 100%;
    border: 1px solid ${globalstyles.colors.lightGray};
    border-radius: 8px;
`
