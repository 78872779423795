import styled from "styled-components";
import { globalstyles } from "../../styles/animations/globalstyles";

export const MembershipType = styled.div`
    background-color: transparent;
    border: 1px solid #dddddd;
    border-radius: 22px;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    font-size: 1rem;
    padding: 0.3rem;
    width: 18rem;
    height: 3rem;
    
    @media (max-width: ${globalstyles.sizes.mobile}) {
        margin: auto;
        width: 80%;
    }
`
