import styled from "styled-components";
import { globalstyles } from "../../styles/animations/globalstyles";

export const Video = styled.video`
  width: auto;
  height: 100%;
  margin: auto;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    width: 100%;
    height: auto;
  }
`

export const CloseIcon = styled.div`
  bottom: 2%;
  color: white;
  cursor: pointer;
  height: 48px;
  position: fixed;
  right: 5%;
  text-decoration: underline;
  transition: ${globalstyles.animations.low};
  width: 48px;
  
  &:hover {
    color: ${globalstyles.colors.gray};
  }
`

export const VideoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 62%);
`