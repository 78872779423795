import styled from "styled-components";
import { globalstyles } from "../../../styles/animations/globalstyles";

export const Unsubscribe = styled.button`
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border-color: transparent;
`;

export const ContactsAmountTitle = styled.div`
  font-size: 22px;
  color: white;
`;

export const ContactsAmount = styled.div`
  font-size: 32px;
  color: white;
  font-weight: bolder;
`;

export const ToolImg = styled.img`
  width: 24px;

  @media only screen and (min-width: ${globalstyles.sizes.desktop}) {
    width: 32px;
  }

  @media only screen and (min-width: ${globalstyles.sizes.large}) {
    width: 40px;
  }
`;

export const EmailTitle = styled.div`
  font-size: 16px;
  color: white;
  text-align: center;
  font-weight: bolder;
`;

export const CardEmailBannerContainer = styled.div`
  width: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type PlanNameProps = {
  top?: string;
  fontSize?: string;
};

export const PlanName = styled.div<PlanNameProps>`
  font-size: ${(props) => props.fontSize || "24px"};
  color: white;
  position: absolute;
  font-weight: bolder;
  left: 50%;
  top: ${(props) => props.top || "50%"};
  transform: translate(-50%, -50%);
`;

export const CardBannerDescription = styled.div`
  display: flex;
  padding: 1rem 0.2rem 2rem;
  width: 100%;

  h4 {
    margin: 0 0 0.7rem 0;
  }
`;

  export const CancellationButtonStyled = styled.button`

`

export const CardBannerContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  position: relative;
`;

type CardBannerProps = {
  width?: string;
};

export const CardBanner = styled.img<CardBannerProps>`
  width: 100%;
  aspect-ratio: 16/9;
  height: 180px;
  border-radius: 30px;
`;

export const PlanListItem = styled.li`
  display: list-item;
  font-size: 12px;
  margin: 0;
  line-height: 21px;
`;

type SubscriptionCardProps = {
  darkMode?: boolean;
};

export const SubscriptionCard = styled.div<SubscriptionCardProps>`
  margin: 1rem auto 0;
  border-radius: 30px;
  background: white;
  overflow: hidden;
  box-shadow: ${globalstyles.boxShadows.centered};
`;
