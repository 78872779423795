import { Container, Title } from "../../../../../../styles/globalcomponents";
import { MembershipButton, PlansContainerStyled } from "./plans.styles";
import { Fragment, useEffect, useState } from "react";
import { MembershipType } from "src/components/membership/membership-type.component";
import { Alert } from "../../../../../../components/alert/alert.component";
import CrispChatBox from "../../../../../../components/crispChatBox/crispChatBox";
import { useMobileScreen } from "../../../../../../hooks/useMobileScreen";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/useRedux";
import { Plan } from "../../../../../../interfaces/plans";
import { PlansService } from "../../../../../../services/plansService";
import { ToastService } from "../../../../../../services/toastService";
import useUserData from "src/hooks/useUserData";
import { PlanCard } from "./planCard.component";
import {
  completeStep,
  moveToFollowingStep,
  setPlan,
  STEPS_INDEXES,
} from "../../../../../../redux/slices/checkout";
import { PLANS_HIERARCHY } from "../plans.data";
import { PlanSelectedDowngradeModal } from "./modals/planSelectedDowngrade.modal";
import { DatesLib } from "src/lib/datesLib";

const toastService = new ToastService();
const plansService = new PlansService();
const dateManager = new DatesLib();

export const PlansOverview = () => {
  const plans = useAppSelector((state) => state.auth.plans);
  const dispatcher = useAppDispatch();
  const dataUser = useUserData();
  const mobileScreen = useMobileScreen();
  const expirationDate = dataUser.suscription.expired_time;
  const canceledPlan = dataUser.stateUser;
  const [organizedPlans, setOrganizedPlans] = useState<Plan[]>([]);
  const [isAnualMembership, setIsAnualMembership] = useState<boolean>(false);
  const [showExpiredPlanAlert, setShowExpiredPlanAlert] = useState(false);
  const [isPlanSelectedDowngradeModalOpen,setIsPlanSelectedDowngradeModalOpen] = useState<boolean>(false);
  let expiredPlan: boolean = false;

  function closePlanSelectedDowngrade() {
    setIsPlanSelectedDowngradeModalOpen(false);
  }

  function reorganizePlans() {
    const userSubscription = dataUser.suscription.name;
    const plansInfoList = [...plans];
    const litePlanIndex = plansInfoList.findIndex(
      (plan: Plan) => plan.suscriptionInfo.name === "Lite"
    );
    const litePlan = plansInfoList.splice(litePlanIndex, 1);

    const starterPlanIndex = plansInfoList.findIndex(
      (plan: Plan) => plan.suscriptionInfo.name === "Starter"
    );
    let starterPlan = plansInfoList.splice(starterPlanIndex, 1);

    if (!isAnualMembership && userSubscription === "Lite") {    
      setOrganizedPlans([...litePlan, ...plansInfoList]);
      return;
    }

    if (userSubscription === "Starter") {
      const planWithoutBasic = plansInfoList.filter(f => f.suscriptionInfo.name !== "Basic");
      setOrganizedPlans([...starterPlan, ...planWithoutBasic]);
      return;
    }

    setOrganizedPlans([...plansInfoList]);
  }

  function switchMembership() {
    setIsAnualMembership((prev) => !prev);
  }

  function getMembershipName() {
    return isAnualMembership ? "Anual" : "Mensual";
  }

  function canPurchasePlan(plan: Plan) {
    return (
      !plansService.checkPlanExpired(expirationDate) &&
      plan.suscriptionInfo.name === dataUser.suscription.name &&
      getMembershipName() === dataUser.suscription.typeSuscription &&
      !expiredPlan
    );
  }

  function handleSelectPlan(plan: Plan) {
    const isALowerPlanThanCurrent = PLANS_HIERARCHY[plan.suscriptionInfo.name] < PLANS_HIERARCHY[dataUser.suscription.name];
    const planIsExpired = dateManager.isDateExpired(dataUser.suscription.expired_time)
    

    if (planIsExpired && isALowerPlanThanCurrent) {
      return setIsPlanSelectedDowngradeModalOpen(true);
    }

    if (isALowerPlanThanCurrent && dataUser.suscription?.isDowngradeScheduled) {
      return toastService.warning(
        "Ya está programada una disminución del plan"
      );
    }
    dispatcher(
      setPlan({
        id: plan.suscriptionInfo.idSuscription,
        frequency: getMembershipName(),
      })
    );
    dispatcher(completeStep({ stepId: STEPS_INDEXES.selectPlan }));
    dispatcher(moveToFollowingStep({ stepId: STEPS_INDEXES.planDescription }));
  }

  useEffect(() => {
    if (expirationDate || canceledPlan) {
      const isDateExpired = plansService.checkPlanExpired(expirationDate);
      const isCanceledPlan = canceledPlan === 2;

      expiredPlan = isDateExpired || isCanceledPlan;
      setShowExpiredPlanAlert(expiredPlan);
    }
  }, []);

  useEffect(() => {
    reorganizePlans();
  }, [plans, isAnualMembership]);

  return (
    <>
      <Title
        color="black"
        fontSize="15px"
        margin="0 0 .5rem"
        responsive="text-align: center;"
        textAlign="center"
      >
        Planes y suscripciones
      </Title>
      <MembershipType>
        <MembershipButton
          onClick={switchMembership}
          active={!isAnualMembership}
        >
          Mensual
        </MembershipButton>
        <MembershipButton
          onClick={switchMembership}
          active={isAnualMembership}
          promotion={"20% off"}
        >
          Anual
        </MembershipButton>
      </MembershipType>

      <Container flexDirection="column" margin="0">
        <Container flexDirection="column">
          <PlansContainerStyled margin="2rem auto 0">
            {organizedPlans.map((plan, index) => {
              const SUBSCRIPTION_ACTIVE = 1;
              const canSeePlanList =
                plan.suscriptionInfo.name !== "Pioneros" &&
                plan.suscriptionInfo.name !== "Trial" &&
                plan.suscriptionInfo.state === SUBSCRIPTION_ACTIVE;
              if (canSeePlanList)
                return (
                  <PlanCard
                    key={`plan-cards-${index}`}
                    plan={plan}
                    onUserSubscription={handleSelectPlan}
                    active={canPurchasePlan(plan)}
                    isAnualMembership={isAnualMembership}
                  />
                );
              return <Fragment key={`plan-cards-${index}`}></Fragment>;
            })}
          </PlansContainerStyled>
        </Container>
      </Container>
      {!mobileScreen ? <CrispChatBox /> : null}
      <Alert
        description="Parece que tu suscripción ya caducó, debes renovar tu plan para seguir disfrutando de MasterTools"
        title="Oops... Tu plan expiró"
        isOpen={showExpiredPlanAlert}
        buttons={[
          {
            text: "Ok",
            handler: () => setShowExpiredPlanAlert(false),
          },
        ]}
      />
      <PlanSelectedDowngradeModal
        isOpen={isPlanSelectedDowngradeModalOpen}
        closeModal={closePlanSelectedDowngrade}
      />
    </>
  );
};
