import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

interface IframeProps {
  url: string;
}

export const IFrame = styled.iframe.attrs<IframeProps>(() => ({
  allowFullScreen: true,
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
}))`
  height: 400px;
  width: 100%;
`;

export const PopupComponent = styled.div`
  background-color: rgb(0 0 0 / 50%);
  display: grid;
  height: 100vh;
  left: 0;
  place-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
`;

interface IPopUpCard {
  fullScreen?: boolean;
  responsive?: string;
  width?: string;
}

export const PopupCard = styled.div<IPopUpCard>`
  border-radius: 25px;
  bottom: ${(props) => props.fullScreen && "6%"};
  width: ${(props) => (props.width ? props.width : "40%")};
  position: fixed;
  left: 0;
  right: 0;
  top: ${(props) => props.fullScreen && "2%"};
  margin: auto;

  @media (max-width: 540px) {
    width: 90%;
  }

  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    ${(props: IPopUpCard) => props.responsive && props.responsive}
  }
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1.5rem;
  top: 0.3rem;
  cursor: pointer;

  > svg {
    cursor: pointer;
  }
`;

export const CloseButtonTootorials = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;

  > svg {
    cursor: pointer;
    width: 80%;
  }
`;

export const VideoContainer = styled.div`
  height: 100%;
  margin: auto;
  overflow: hidden;
  width: 100%;
`;
