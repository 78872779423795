import styled from "styled-components";
import { globalstyles } from "../../../../styles/animations/globalstyles";

import {ConfirmButton} from "../../../../styles/globalcomponents";

export const InputField = styled.textarea`
  border: none;
  resize: vertical;
  outline: none;
  padding: 1.5rem 1rem;
  height: auto;
  border-radius: 1rem;
  background-color: white;
  width: 100%;
  font-size: 11px;
  margin: 1rem auto;
  &::-webkit-scrollbar {
    display: block !important;
  }
`;

export const FormTitle = styled.div`
  color: ${globalstyles.colors.grayDarkness};
  font-weight: 800;
  font-size: 16px;
  margin: 1rem 0 1.5rem 0;
`;

export const FormCard = styled.div`
  border-radius: 1rem;
  background-color: ${globalstyles.colors.grayLighter};
  padding: 1rem 2rem 2rem;
  margin: 2rem auto;
`;

type TextProps = {
  fontSize?: string;
  margin?: string;
  width?: string;
};

export const Text = styled.div<TextProps>`
  color: black;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: 500;
  margin: ${(props) => props.margin || "unset"};
  text-align: center;
  width: ${(props) => props.width || "100%"};
`;

export const FeatureValue = styled.div`
  font-size: 0.75rem;
  color: ${globalstyles.colors.lightOrange};
`;

interface IFeatureName {
  color?: string;
}

export const FeatureName = styled.div<IFeatureName>`
  font-size: 0.75rem;
  color: ${(props) => (props.color ? props.color : "white")};
`;

export const FormContainer = styled.div`
  margin: .5rem auto;
`;

export const LoaderLayout = styled.div`
  align-items: center;
  background-color: ${globalstyles.colors.loaderLayout};
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
`;

export const VideoStyled = styled.video`
  width: clamp(300px, 90%, 50rem);
  height: auto;
  aspect-ratio: 16/9;
  border-radius: 27px;
  margin-top: 1rem;
  cursor: pointer;
`

export const DecreaseButtonStyled = styled(ConfirmButton)`
  margin: 2rem 0 1rem;
`
export const AcceptButtonStyled = styled(ConfirmButton)`
`

export const CancelButtonStyled = styled(ConfirmButton)`
  background-color: ${globalstyles.colors.yellow};
  color: ${globalstyles.colors.black};
  font-weight: 700;
  
  &:hover {
    background-color: ${globalstyles.colors.lightYellow};
  }
`

export const ConfirmInputStyled = styled.input`
  border-color: transparent;
  appearance: none;
  display: block;
  padding: .8rem 1.5rem;
  border-radius: 10px;
  font-weight: 700;
  width: 100%;
  background-color: ${globalstyles.colors.grayLighter};
  
  :focus-visible, :focus {
    outline: none;
  }
`

export const StartCountDownButtonStyled = styled(DecreaseButtonStyled)`
  background-color: transparent;
  color: ${globalstyles.colors.black};
  margin: 0;
  
  :hover {
    background-color: transparent; 
  }
`
export const TitleStyled = styled.h1`
  text-align: center;
  margin-bottom: 0;
`

export const TermsStyled = styled.p`
  & small {
    font-weight: 500;
    font-size: 13px;
  }
`

export const DowngradePictureStyled = styled.img`
  object-fit: contain;
`
