import {SpiralLoadingIcon} from "../../../../../../../components/loadingIcon/loadingIcon.component";
import {TermsStyled} from "../../../../unsubscribe/unsubscribe.styles";
import {ConfirmButton, Container, CustomText} from "../../../../../../../styles/globalcomponents";
import {config} from "../../../../../../../config";
import {useEffect, useState} from "react";
import {PlansService, PRODUCT_IDS} from "../../../../../../../services/plansService";
import {useAppSelector} from "../../../../../../../hooks/useRedux";
import {ToastService} from "../../../../../../../services/toastService";
import {LeftArrowIcon} from "../../../../../../../components/icons";
import {ConfirmTabParagraphs} from "./confirmTabParagraphs.component";
import {GetSubscriptionStateResponse} from "../../../../../../../interfaces/plans";

interface Props {
    closePurchaseModal: (force?: boolean) => void
    openSuccessModal: () => void
    setTabIndex: (index: number) => void
    hasAdditionalTools: boolean
}

const confirmPlanImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-icon.png`
const confirmPlanRenewalImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-renewal-icon.png`
const planService = new PlansService()
const toastService = new ToastService()

export function ConfirmPlanTab({closePurchaseModal, setTabIndex, hasAdditionalTools, openSuccessModal}: Props) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const plans = useAppSelector((state) => state.auth.plans)
    const selectedPlan = useAppSelector((state) => state.checkout.selectedPlan)
    const selectedPlanInfo = plans.find(plan => plan.suscriptionInfo.idSuscription === selectedPlan?.id)
    const [validationData, setValidationData] = useState<GetSubscriptionStateResponse | undefined>(undefined)
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [paymentApproved, setPaymentApproved] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const newPlanPrice = (validationData?.isApportionmentPrice ? validationData.apportionmentPrice : validationData?.priceTotal) ?? 0

    async function validateSubscription() {
        try {
            setIsLoading(true)
            if(!selectedPlan || !selectedPlanInfo) throw new Error()

            const response = await planService.getUserSubscriptionStateNewest({
                productId: PRODUCT_IDS.MasterTools,
                newPlanInfo: selectedPlanInfo.suscriptionInfo,
                newPlanPayFrequency: selectedPlan.frequency
            })

            if (!response) throw new Error()
            setValidationData(response)
        }catch (e) {
            toastService.error("No pudimos verificar tu plan, contacta a soporte")
        }finally {
            setIsLoading(false)
        }
    }

    async function handleChangePlan() {
        try {
            setIsSubmitting(true)
            if(!validationData) throw new Error("Subscription validation info not found")

            const response = await planService.handlePayment(
                dataUser.email,
                validationData.codePlanPaytool,
                395,
                validationData.typeChange,
                validationData.apportionmentPrice || validationData.priceTotal || 0
            );

            setPaymentApproved(response.data.suscriptionUpdated)
            setIsSubmitting(false)
            toastService.success("¡Compra exitosa!");
        } catch (error) {
            toastService.error("¡Tu pago ha sido rechazado!");
        }
    }

    function handleChangeToNextTab() {
        setTabIndex(1)
    }

    function handleShowSuccessfulMessage() {
        openSuccessModal()
        toastService.success("¡Se terminó tu compra! Revisa tu correo electrónico en unos momentos")
    }

    useEffect(() => {
        validateSubscription()
    }, [])

    return (
        <Container flexDirection={"column"} alignItems={"center"} maxWidth={"45rem"} padding={"0 .5rem"}>
            <img src={getImageSource(validationData?.typeChange ?? '')} alt={"MasterTools icon plan confirmation"} width={"170px"} height={"170px"} />
            <h2 className={"text-center m-0"}>
                {getTitleCopy(validationData?.typeChange ?? '')}
            </h2>
            {
                !isLoading && validationData !== undefined ?
                    <ConfirmTabParagraphs
                        changeType={validationData.typeChange ?? ''}
                        currentPlanName={dataUser.suscription.name}
                        newPlan={selectedPlanInfo!.suscriptionInfo}
                        nextPaymentDate={validationData.nextPayment ?? ''}
                        price={newPlanPrice}
                    />
                :
                    <SpiralLoadingIcon width={"3rem"} />
            }
            <TermsStyled>
                <input type="checkbox" onChange={() => setTermsAccepted(prev => !prev)}/>
                <small>
                    <i>Entiendo que al dar click en el botón “{getSubmitCopy(validationData?.typeChange ?? '')}” estoy realizando un pago proporcional a los días de uso de la suite de herramientas en MasterTools y autorizo que sea cobrado de la tarjeta de crédito registrada en mi cuenta. También estoy aceptando los <a href="https://mastertools.com/politica-servicio" rel="noreferrer" target="_blank">términos de compra</a> y servicio de MasterTools.</i>
                </small>
            </TermsStyled>
            {
                isSubmitting ?
                    <SpiralLoadingIcon width={"3rem"} />
                :
                    !paymentApproved ?
                        <div>
                            <ConfirmButton onClick={handleChangePlan} disabled={!termsAccepted || isSubmitting}>
                                { getSubmitCopy(validationData?.typeChange ?? '') }
                            </ConfirmButton>
                            <CustomText textAlign={"center"} onClick={() => closePurchaseModal()} cursor={"pointer"} fontSize={"13px"} textDecoration={"underline"} margin={"1rem 0"}>
                                Cancelar
                            </CustomText>
                        </div>
                    :
                        <div>
                            {
                                hasAdditionalTools ?
                                    <ConfirmButton onClick={handleChangeToNextTab}>
                                        ¡CONTINUAR! <LeftArrowIcon style={{transform: "rotateZ(180deg)", marginLeft: ".5rem"}} fill={"white"} size={"10px"} />
                                    </ConfirmButton>
                                :
                                    <ConfirmButton onClick={handleShowSuccessfulMessage}>
                                        FINALIZAR
                                    </ConfirmButton>
                            }
                        </div>

            }
        </Container>
    )
}

function getSubmitCopy(typeChange: string) {
    if(typeChange === 'Upgrade') return "¡QUIERO MI NUEVO PLAN!"
    if(typeChange === 'Downgrade') return "¡DISMINUIR MIS FUNCIONALIDADES!"
    if(typeChange === 'Renewal') return "¡Ya comenzábamos a extrañarte!"
    return "¡CONFIRMAR!"
}
function getTitleCopy(typeChange: string) {
    if(typeChange === 'Upgrade') return "¡Uff! ¡Qué buena elección!"
    if(typeChange === 'Downgrade') return "¿Listo para utilizar MasterTools a un menor costo?"
    if(typeChange === 'Renewal') return "¡Ya comenzábamos a extrañarte!"
    return "¡Hola Tooler!"
}

function getImageSource(typeChange: string) {
    if(typeChange === 'Renewal') return confirmPlanRenewalImg
    return confirmPlanImg
}
