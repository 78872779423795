import { useEffect, useState } from "react";
import { Checkbox } from "../../../../../components/checkbox/checkbox.component";
import {Container, ContainerItem} from "../../../../../styles/globalcomponents";
import { Input } from "../../../../../components/inputs/Input.component";
import { data } from "./questions.data";

interface Props {
  setFormData: (newState: any) => void;
}

export const UnsubscribeFormQuestions = ({ setFormData }: Props) => {
  const [options, setOptions] = useState(data);
  const [otherInput, setOtherInput] = useState("");

  useEffect(() => {
    const whyCancel = `${options
        .filter((option) => option.checked)
        .map((option) => option.name)
        .join(",")}${otherInput !== "" ? `,${otherInput}` : ""}`

    setFormData({whyCancel});
  }, [options, otherInput]);

  return (
    <Container display="block">
      <ContainerItem rowGap={".5rem"} display={"grid"} gridTemplateColumns={"1fr"} responsiveLg={"grid-template-columns: 1fr 1fr"}>
        {options.map((item, i) => (
          <Checkbox
            onClick={() => {
              setOptions((prevState) =>
                prevState.map((input) =>
                  input.name === item.name
                    ? {
                        ...input,
                        checked: !item.checked,
                      }
                    : input
                )
              );
            }}
            label={item.name}
            checked={item.checked}
          />
        ))}
      </ContainerItem>

      {options[options.length - 1].checked && (
        <ContainerItem padding={"0 2rem"} margin={"1rem 0 0"}>
          <Input
            borderColor={"#A5A5A5"}
            backgroundColor={"white"}
            padding={"10px 15px"}
            placeholder="Especifique el motivo"
            value={otherInput}
            onChange={(e) => setOtherInput(e.target.value)}
          />
        </ContainerItem>
      )}
    </Container>
  );
};
