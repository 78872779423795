import {SubscriptionInfo} from "../../../../../../../interfaces/plans";
import {getFormattedNumber} from "../../../../../../../lib/numberFormat";

interface ConfirmTabParagraphsProps {
    changeType: string,
    currentPlanName: string,
    newPlan: SubscriptionInfo,
    price: number,
    nextPaymentDate: string,
}

export function ConfirmTabParagraphs({changeType, newPlan, currentPlanName, nextPaymentDate, price}: ConfirmTabParagraphsProps) {
    const formattedPrice = getFormattedNumber(price)

    if(changeType === 'Upgrade') {
        return (
            <div>
                <p>
                    Lo nuestro continúa, pero mejor que antes <strong>;)</strong> Adiós a las limitaciones del plan <strong>{currentPlanName}</strong> y hola a los beneficios del plan <strong>{newPlan.name}</strong> cuando toques el botón.
                </p>
                <p>
                    Cobraremos <strong>${formattedPrice} USD</strong> a tu tarjeta por el uso de tu nuevo plan durante los días antes del próximo cobro: <strong>{nextPaymentDate}</strong>
                </p>
                <p>
                    Y a partir del próximo cobro pagarías <strong>${newPlan.monthPrice} USD</strong> por tu plan <strong>{newPlan.name}</strong>
                </p>
                <p className={"text-center"}>
                    <strong>Solo toca el botón para confirmar tu nueva elección!</strong>
                </p>
            </div>
        )
    }

    if(changeType === 'Downgrade') {
        return (
            <div>
                <p>
                    Nada mejor que acceder a oportunidades únicas! Sólo por esta ocasión podrás disfrutar de las funcionalidades del plan <strong>{newPlan.name}</strong> por un valor de: <strong>${formattedPrice} USD</strong> que será cargado en tu próxima fecha de cobro <strong>{nextPaymentDate}</strong>. Así podrás utilizar un plan de MasterTools a un menor costo del valor actual.
                </p>
                <p>
                    *No cobraremos nada en este momento ;)
                </p>
                <p className={"text-center"}>
                    <strong>¡Toca el botón para empezar a usar MasterTools al menor costo!</strong>
                </p>
            </div>
        )
    }
    if(changeType === 'Renewal') {
        return (
            <div>
                <p>
                    En toda relación debe haber puntos claros y estos son los nuestros! A partir de ahora tu suscripción al plan <strong>{currentPlanName}</strong> quedará activa, cobraremos <strong>${formattedPrice} USD</strong> a tu tarjeta y no volveremos a cobrar hasta <strong>{nextPaymentDate}</strong>.
                </p>
                <p>
                    Y a partir del próximo cobro de tu plan <strong>{newPlan.name}</strong> pagarías: <strong>${getFormattedNumber(newPlan.monthPrice)} USD</strong>.
                </p>
                <p className={"text-center"}>
                    <strong>Solo toca el botón para confirmar tu nueva elección!</strong>
                </p>
            </div>
        )
    }

    return (
        <p>No se encontró información</p>
    )
}
