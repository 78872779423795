import {PlansService} from "../../../../services/plansService";

export function verifyIsInvalidPlan(expireDate: string, planStatus: number) {
    const plansService = new PlansService()
    const CANCELLED = 2
    const isPlanExpired = plansService.checkPlanExpired(expireDate)
    const isPlanCancelled = planStatus === CANCELLED

    return isPlanExpired || isPlanCancelled
}
