import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useRedux";
import {
  Card,
  Container,
  ContainerItem,
  CustomTitle,
} from "../../../../styles/globalcomponents";

import {
  AcceptButtonStyled,
  CancelButtonStyled,
  DecreaseButtonStyled,
  DowngradePictureStyled,
  FormContainer,
} from "./unsubscribe.styles";

import { useState } from "react";
import { ToastService } from "../../../../services/toastService";
import { PlansService } from "../../../../services/plansService";
import { Redirect, useHistory } from "react-router-dom";
import { config } from "src/config";
import { LeftArrowIcon } from "../../../../components/icons";
import { BackButtonStyled } from "../../../../components/ui/backButton/index.styles";
import { ChangeToLitePlanModal } from "./modal/ChangeToLitePlanModal.component";
import { UnsubscribeForm } from "./unsubscribeForm.component";
import { AccountCancellationModal } from "./accountCancellationModal.component";
import { validateIfDowngradeIsAlreadySchedule } from "../subscriptionTab.component";
import { LocalStorageKeys } from "../../../../redux/localStorageKeys";
import { loginReducerInitialState } from "../../../../interfaces/redux";
import { closeSession } from "../../../../redux/slices/auth";

export interface UnsubscribeFormState {
  whyCancel: string;
  migration: boolean;
  reEntry: number;
  observations: string;
  understanding: boolean;
}

interface Props {
  onAbortCancellation: () => void
  isEmailCancellation?: boolean
}

const FORM_INITIAL_STATE = {
  whyCancel: "",
  migration: false,
  reEntry: 6,
  observations: "",
  understanding: false,
}

const toastService = new ToastService();
const plansService = new PlansService();

export const Unsubscribe = ({ onAbortCancellation, isEmailCancellation = false }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory();
  const { auth } = useAppSelector((state) => state);
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const alreadyHasLitePlanOrIsTrial = dataUser.suscription.name === "Lite" || dataUser.suscription.name === "Trial"
  const plans = useAppSelector((state) => state.auth.plans);
  const darkMode = useAppSelector((state) => state.session.darkMode);
  const [isOpenChangeToLiteModal, setIsOpenChangeToLiteModal] = useState<boolean>(false)
  const [isOpenCancellationModal, setIsOpenCancellationModal] = useState(false);
  const [formState, setFormState] = useState<UnsubscribeFormState>(FORM_INITIAL_STATE);
  const isFormValid = formState.observations !== "" && formState.understanding && formState.whyCancel !== ""
  const haveAccountCancelled = dataUser.cancelRequest === 1
  const [isDowngradeDisabled, setIsDowngradeDisabled] = useState<boolean>(true)
  const isMonthlySubscription = dataUser.suscription.typeSuscription === 'Mensual'

  function closeChangeToLiteModal() {
    setIsOpenChangeToLiteModal(false)
  }

  function closeCancellationModal() {
    setIsOpenCancellationModal(false)
  }

  function openChangePlanModal() {
    setIsOpenChangeToLiteModal(true)
  }

  function setFormData(newState: any) {
    setFormState((prev) => ({ ...prev, ...newState }))
  }

  function closeUserSession() {
    localStorage.removeItem(LocalStorageKeys.userLoggedInfo);
    const closeSessionUser = loginReducerInitialState();
    dispatch(closeSession(closeSessionUser));
  }

  function handleRedirectToProfile() {
    setTimeout(() => {
      history.push("/profile#account")
      closeUserSession()
      toastService.warning("Redirigiendo al perfil...")
    }, 3000)
  }

  async function handleUnsubscribeUser() {
    const message = isEmailCancellation ? "¡Herramienta desactivada con éxito!" : "La cancelación de su plan se ha realizado correctamente";

    try {
      if (dataUser.existPaytoolLogic) {
        await plansService.unsubscribeByPaytool({
          email: dataUser.email,
          product: isEmailCancellation ? "Email" : "Mastertools",
        });
      } else {
        await plansService.unsubscribe({
          whyCancel: formState.whyCancel,
          migration: formState.migration,
          reEntry: formState.reEntry,
          observations: formState.observations,
          email: dataUser.email,
          name: dataUser.nameUser,
          lastName: dataUser.lastUser,
          phone: dataUser.cellphone,
          expirationDate: dataUser.suscription.expired_time,
          plan: dataUser.suscription.name,
        });
      }
      toastService.custom(message);
      toastService.success("Su solicitud de cancelación se ha enviado correctamente");
    } catch (error) {
      console.log("🚧🚧Error", error)
      toastService.error("Hubo un problema al tratar de cancelar tu plan. Por favor, intentalo mas tarde.");
    } finally {
      handleRedirectToProfile();
    }

    setIsOpenCancellationModal(false);
  }

  async function checkIfDowngradeIsScheduled() {
    if (alreadyHasLitePlanOrIsTrial) {
      setIsDowngradeDisabled(true)
    } else {
      const isDowngradeScheduled = dataUser.suscription.isDowngradeScheduled
      setIsDowngradeDisabled(isDowngradeScheduled)
    }
  }

  useEffect(() => {
    checkIfDowngradeIsScheduled()
  }, []);

  if (haveAccountCancelled) return <Redirect to="/profile#my-subscriptions" />

  return (
    <>
      <Container display={"grid"} gap={"1rem"} gridTemplateColumns={"1fr"} maxWidth={"100%"} responsiveLg={(!isEmailCancellation && !alreadyHasLitePlanOrIsTrial && isMonthlySubscription) ? "grid-template-columns: 1fr 65%" : ""}>
        {(!isEmailCancellation && !alreadyHasLitePlanOrIsTrial && isMonthlySubscription) &&
          <ContainerItem>
            <Card
              backgroundColor={darkMode ? "#0d0d0d" : "white"}
              padding={"1rem 2rem 2rem"}
              position={"relative"}
            >
              <Container
                margin={"auto"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <BackButtonStyled onClick={onAbortCancellation}>
                  <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
                <DowngradePictureStyled src={`${config.urls.res}/platform/mastertools-avatar/apps.png`} width={"200px"} height={"300px"} />
                <CustomTitle color="black" margin="2rem auto 1rem auto" fontWeight={"800"}>
                  ¡Aún podemos continuar!
                </CustomTitle>
                <small className={"text-center"}>Toca en el botón y aprovecha esta única oferta</small>
                <DecreaseButtonStyled
                  onClick={() => !isDowngradeDisabled && openChangePlanModal()}
                  disabled={isDowngradeDisabled}
                >
                  Disminuir plan
                </DecreaseButtonStyled>
              </Container>
            </Card>
          </ContainerItem>
        }
        <ContainerItem>
          <Card
            backgroundColor={darkMode ? "#0d0d0d" : "white"}
            padding={"0 2rem 2.5rem 2rem"}
            overflow={"auto"}
            height={"100%"}
            position={"relative"}
          >
            <Container
              margin={"auto"}
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {(isEmailCancellation || alreadyHasLitePlanOrIsTrial) &&
                <BackButtonStyled onClick={onAbortCancellation}>
                  <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
              }
              <CustomTitle color="black" margin="2rem auto 1rem auto" fontWeight={"800"}>
                {auth.dataUser.nameUser} ¡Lamentamos que te vayas!
              </CustomTitle>
              <CustomTitle color="black" margin="0 auto" fontSize={"16px"}>
                ¿Estás segur@ que deseas cancelar tu plan?
              </CustomTitle>
              <FormContainer>
                <UnsubscribeForm setFormData={setFormData} formState={formState} isEmailCancellation={isEmailCancellation} />
                <Container alignItems={"center"} justifyContent={"center"} gap={"2rem"} margin={"2rem 0"}>
                  <CancelButtonStyled disabled={!isFormValid} onClick={() => isFormValid && setIsOpenCancellationModal(true)}>
                    {isEmailCancellation ? "Desactivar herramienta" : "Cancelar mi cuenta"}
                  </CancelButtonStyled>
                  <AcceptButtonStyled onClick={handleRedirectToProfile}>
                    ¡Ni pensarlo!
                  </AcceptButtonStyled>
                </Container>
              </FormContainer>
            </Container>
          </Card>
        </ContainerItem>
      </Container>
      <AccountCancellationModal onUnsubscribeUser={handleUnsubscribeUser} isOpen={isOpenCancellationModal} onClose={closeCancellationModal} isEmailCancellation={isEmailCancellation} />
      <ChangeToLitePlanModal isOpen={isOpenChangeToLiteModal} closeModal={closeChangeToLiteModal} />
    </>
  );
};
