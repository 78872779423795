export interface State {
    showOverview: boolean,
    showDowngrade: boolean,
    showUnSubscribe: boolean,
    showPlans: boolean,
    isEmailCancellation: boolean,
    onlyAdditionalTools: boolean
}
type ActionType = "showUnsubscribe" | "showDowngrade" | "showOverview" | "showPlans";
type ActionPayload = {
    isEmailCancellation: boolean,
    onlyAdditionalTools: boolean
}
interface Action {
    type: ActionType,
    payload?: ActionPayload
}

const CLEAN_STATE: State = {
    showOverview: false,
    showDowngrade: false,
    showUnSubscribe: false,
    showPlans: false,
    isEmailCancellation: false,
    onlyAdditionalTools: false
}

export function SubscriptionTabReducer(state: State, {type, payload}: Action) {
    const actionsType = {
        "showUnsubscribe": {
            ...CLEAN_STATE,
            showUnSubscribe: true,
            isEmailCancellation: !!payload && payload.isEmailCancellation
        },
        "showDowngrade": {
            ...CLEAN_STATE,
            showDowngrade: true
        },
        "showOverview": {
            ...CLEAN_STATE,
            showOverview: true
        },
        "showPlans": {
            ...CLEAN_STATE,
            showPlans: true,
            onlyAdditionalTools: !!payload && payload.onlyAdditionalTools
        }
    }
    return actionsType[type] ?? state
}
