import {Container, CustomText, CustomTitle} from "../../../../../styles/globalcomponents";
import {numberFormat} from "../../../../../lib/numberFormat";
import {CheckoutSummaryHeaderStyled} from "./plansCheckoutSummary.styles";
import {useAppSelector} from "../../../../../hooks/useRedux";
import {GetCurrentPlan} from "../../../../../services/addonService";

interface SummaryHeaderProps {
}

const ACTIVE_STATE = 1

export function SummaryHeaderAddons({}: SummaryHeaderProps) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const currentAddonPlan = dataUser.addonsInfo.emailContacts.find((element) => element.state === ACTIVE_STATE)
    const currentAddonPlanInfo = GetCurrentPlan(currentAddonPlan?.quantity ?? 0)

    return (
        <CheckoutSummaryHeaderStyled>
            <Container flexDirection={"column"} alignItems={"flex-start"} gap={".5rem"}>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0"} fontSize={"14px"} color={"black"}>
                    Plan actual
                </CustomTitle>
                <CustomTitle fontWeight={"500"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"black"}>
                    {currentAddonPlanInfo.name}
                </CustomTitle>
            </Container>
            <Container flexDirection={"column"} alignItems={"flex-end"} gap={".5rem"}>
                <CustomText textAlign={"end"} fontWeight={"400"} fontSize={"11px"}>
                    Incluido en tu plan principal
                </CustomText>
                <CustomText fontSize={"16px"} fontWeight={"400"} textAlign={"right"}>
                    ${numberFormat.format(currentAddonPlanInfo.price)} USD
                </CustomText>
            </Container>
        </CheckoutSummaryHeaderStyled>
    )
}
