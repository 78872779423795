import {
    PlanDescriptionContainer,
    PlanImage,
    PlanDetailsWrapper,
    PlanLink,
    PlanSelection, PlanInfoWrapper
} from "../planDescription.styles";
import {PlanTools} from "./planTools.component";
import {useAppSelector} from "../../../../../../../hooks/useRedux";
import {SuccessIcon} from "../../../../../../../components/icons";
import {PLANS_BANNERS} from "../../plans.data";
import {PlanPriceNumber} from "../../planSelectionTab/plans.styles";
import {PlanDescriptionModal} from "../../planSelectionTab/modals/planDescription.modal";
import {useState} from "react";
import {numberFormat} from "../../../../../../../lib/numberFormat";

interface PlanSelectedProps {
}

export function PlanSelected({}: PlanSelectedProps) {
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false)
    const planSelected  = useAppSelector((state) => state.checkout.selectedPlan)
    const plans = useAppSelector((state) => state.auth.plans)
    const planInfo = plans.find(plan => plan.suscriptionInfo.idSuscription === planSelected?.id)
    const planPrices:any = {
        'Anual': planInfo?.suscriptionInfo.anualPrice,
        'Mensual': planInfo?.suscriptionInfo.monthPrice
    }
    const planPayFrequencies:any = {
        'Anual': "USD / año",
        'Mensual': "USD / mes"
    }
    const planPrice: string = numberFormat.format(planPrices[planSelected!.frequency] ?? 0)
    const planPayFrequency: string = planPayFrequencies[planSelected!.frequency] ?? ""
    const subscriptionName = planInfo?.suscriptionInfo.name ?? ""
    const backgroundImage = PLANS_BANNERS[subscriptionName.toLowerCase()] ?? PLANS_BANNERS[0]

    function closeDescriptionModal() {
        setIsDescriptionModalOpen(false)
    }

    return (
        <>
            <PlanDescriptionContainer>
                <PlanInfoWrapper>
                    <PlanImage backgroundImage={backgroundImage}>
                        {subscriptionName}
                    </PlanImage>
                    <PlanDetailsWrapper>
                        <PlanPriceNumber>
                            ${planPrice} {planPayFrequency}
                        </PlanPriceNumber>
                        <PlanLink onClick={() => setIsDescriptionModalOpen(true)}>Ver descripción del plan</PlanLink>
                    </PlanDetailsWrapper>
                </PlanInfoWrapper>
                <div>
                    <PlanTools plan={planInfo}/>
                </div>
                <PlanSelection>
                    <SuccessIcon width={"30px"}/> Plan Seleccionado
                </PlanSelection>
            </PlanDescriptionContainer>
            <PlanDescriptionModal planName={subscriptionName} isOpen={isDescriptionModalOpen} closeModal={closeDescriptionModal}/>
        </>
    )
}
