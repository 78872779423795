import {Card, Container, ContainerItem} from "../../../../styles/globalcomponents";
import {useAppSelector} from "../../../../hooks/useRedux";
import {
    DecreaseButtonStyled,
    StartCountDownButtonStyled,
    TitleStyled,
    VideoStyled
} from "./unsubscribe.styles";
import {useEffect, useRef, useState} from "react";
import {PreUnsubscriptionCountdown} from "./preUnsubscriptionCountdown.component";
import {ChangeToLitePlanModal} from "./modal/ChangeToLitePlanModal.component";
import {LeftArrowIcon} from "../../../../components/icons";
import {BackButtonStyled} from "../../../../components/ui/backButton/index.styles";
import {config} from "../../../../config";

interface Props {
    onAbortCancellation: () => void
    onStartCancellation: (isEmailCancellation: boolean) => void
}

const VIDEO_URL = `${config.urls.res}/media/videos/DESAFIO_MT_VIDEO_DOWNSELL.mp4`
export function PreUnsubscribe({onAbortCancellation, onStartCancellation}: Props) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const darkMode = useAppSelector((state) => state.session.darkMode);
    const [isCountingDown, setIsCountingDown] = useState<boolean>(true)
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isOpenChangeToLiteModal, setIsOpenChangeToLiteModal] = useState<boolean>(false)

    function startVideoWhenMouseClicked() {
        if(videoRef !== null && videoRef !== undefined) {
            videoRef.current!.paused ? videoRef.current!.play() : videoRef.current!.pause()
            videoRef.current!.muted = false
        }
    }

    function onCountDownStarts() {
        startVideoWhenMouseClicked()
    }

    function closeChangeToLiteModal() {
        setIsOpenChangeToLiteModal(false)
    }

    function onCountdownCompleted() {
        setIsCountingDown(false)
    }

    function openChangePlanModal() {
        setIsOpenChangeToLiteModal(true)
    }

    useEffect(() => {
        const videoElement = videoRef.current
        videoElement!.addEventListener('click', startVideoWhenMouseClicked)
        return () => {
            videoElement!.removeEventListener('click', startVideoWhenMouseClicked)
        }
    }, [])

    return (
        <Card
            backgroundColor={darkMode ? "#0d0d0d" : "white"}
            padding={"0 2.5rem 2rem"}
            position={"relative"}
        >
            <Container
                margin={"auto"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                padding={"2rem 0 0 "}
            >
                <BackButtonStyled onClick={onAbortCancellation}>
                    <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
                <TitleStyled>
                    {dataUser.nameUser ? `${dataUser.nameUser}, lamentamos que te vayas` : "Lamentamos que te vayas" }
                </TitleStyled>
                <p>¿Estás seguro que deseas cancelar tu plan?</p>
                <ContainerItem alignItems={"center"}>
                    <VideoStyled ref={videoRef} src={VIDEO_URL} muted={true} loop={true} width={"400px"} height={"300px"}/>
                </ContainerItem>
                <DecreaseButtonStyled onClick={openChangePlanModal}>
                    Disminuir plan
                </DecreaseButtonStyled>
                {
                    isCountingDown ?
                        <PreUnsubscriptionCountdown onCompleted={onCountdownCompleted} onStarts={onCountDownStarts} />
                    :
                        <StartCountDownButtonStyled onClick={() => onStartCancellation(false)}>
                            Continuar con la cancelación
                        </StartCountDownButtonStyled>
                }
            </Container>
            <ChangeToLitePlanModal isOpen={isOpenChangeToLiteModal} closeModal={closeChangeToLiteModal} />
        </Card>
    )
}
