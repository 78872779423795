export const AdditionalToolEmailSpecifications: string[] = [
    "Automatizaciones",
    "Campañas de Envío Masivo",
    'Constructor "Drag & Drop"',
    "Importar / Exportar Suscriptores"
]

export const addonsDataMock = [
  {
    id: 0,
    name: "0 Contactos",
    contacts: 0,
    price: 0,
    code: "",
  },
  {
    id: 1,
    name: "500 Contactos",
    contacts: 500,
    price: 9,
    code: "E2A0E0J",
  },
  {
    id: 2,
    name: "1.000 Contactos",
    contacts: 1000,
    price: 18,
    code: "P5S2D6B",
  },
  {
    id: 3,
    name: "2.500 Contactos",
    contacts: 2500,
    price: 45,
    code: "Y3N6F8E",
  },
  {
    id: 4,
    name: "5.000 Contactos",
    contacts: 5000,
    price: 75,
    code: "H3J0O5Z",
  },
  {
    id: 5,
    name: "10.000 Contactos",
    contacts: 10000,
    price: 129,
    code: "E6A8E4J",
  },
  {
    id: 6,
    name: "25.000 Contactos",
    contacts: 25000,
    price: 219,
    code: "J8P2F8B",
  },
  {
    id: 7,
    name: "50.000 Contactos",
    contacts: 50000,
    price: 319,
    code: "O7T0D2N",
  },
  {
    id: 8,
    name: "75.000 Contactos",
    contacts: 75000,
    price: 409,
    code: "I5Q0B3C",
  },
  {
    id: 9,
    name: "100.000 Contactos",
    contacts: 100000,
    price: 499,
    code: "V3T3I5O",
  },
];

export const addonsData = [
  {
    id: 0,
    contacts: 0,
    price: 0,
    position: 0,
    percentage: 0,
    costPerContact: "0",
    urlCheckout: "",
  },
  {
    id: 1,
    contacts: 500,
    price: 9,
    position: 0,
    percentage: 5,
    costPerContact: "0.018",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=62134yds",
  },
  {
    id: 2,
    contacts: 1000,
    price: 18,
    position: 8,
    percentage: 15,
    costPerContact: "0.018",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=ts7yzg0w",
  },
  {
    id: 3,
    contacts: 2500,
    price: 45,
    position: 8,
    percentage: 25,
    costPerContact: "0.018",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=teqxa6vx",
  },
  {
    id: 4,
    contacts: 5000,
    price: 75,
    position: 8,
    percentage: 35,
    costPerContact: "0.015",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=rnufcv92",
  },
  {
    id: 5,
    contacts: 10000,
    price: 129,
    position: 8,
    percentage: 45,
    costPerContact: "0.0129",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=tqlz517p",
  },
  {
    id: 6,
    contacts: 25000,
    price: 219,
    position: 8,
    percentage: 65,
    costPerContact: "0.00876",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=v238vrxc",
  },
  {
    id: 7,
    contacts: 50000,
    price: 319,
    position: 8,
    percentage: 75,
    costPerContact: "0.00638",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=jjj33dqj",
  },
  {
    id: 8,
    contacts: 75000,
    price: 409,
    position: 8,
    percentage: 85,
    costPerContact: "0.00545",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=034v56py",
  },
  {
    id: 9,
    contacts: 100000,
    price: 499,
    position: 8,
    percentage: 100,
    costPerContact: "0.00499",
    urlCheckout: "https://pay.hotmart.com/H58720700F?off=93y2aqlz",
  },
];
