import {useEffect, useState} from "react";
import {PlansService} from "src/services/plansService";
import {config} from "../../../../config";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useRedux";
import {LimitsTool} from "../../../../interfaces/plans";
import {Card, ConfirmButton, Container, ContainerItem, CustomTitle,} from "../../../../styles/globalcomponents";
import {PlanDescription} from "../plan/tabs/planSelectionTab/plans.styles";
import ToolCard from "./toolCard/toolCard.compontent";
import PlanListItems from "./planDescription.component";
import {
  CardBanner,
  CardBannerContainer,
  CardBannerDescription,
  PlanName,
  SubscriptionCard,
  ToolImg,
  Unsubscribe,
} from "../subscription.styles";
import {ToastService} from "src/services/toastService";
import DateSubscriptionInfo from "src/components/dateSubscriptionInfo/dateSubscriptionInfo.component";
import {globalstyles} from "../../../../styles/animations/globalstyles";
import {DowngradePictureStyled} from "../unsubscribe/unsubscribe.styles";
import {resetCheckoutState} from "../../../../redux/slices/checkout";
import {ChatRedirectModal} from "./modals/chatRedirectModal";
import {ExplainToolTip} from "../../../../components/tooltip/tooltip.component";
import {DatabaseStates} from "../../../../redux/consts";

interface Props {
  showDowngrade: () => void
  showPlans: () => void
  showUnsubscribe: (isEmailCancellation?: boolean) => void
}

const plansService = new PlansService();
const toastService = new ToastService()

export const SubscriptionPanelOverview = ({ showDowngrade, showUnsubscribe, showPlans }: Props) => {
  const ICONS = ["copy", "funnels", "link", "tienda", "emailtool", "connecttool"];
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const dispatcher = useAppDispatch()
  const darkMode = useAppSelector((state) => state.session.darkMode);
  const [, setContactsAmount] = useState<string>();
  const [currentPlan, setCurrentPlan] = useState<LimitsTool>();
  const [disabledPlan, setDisabledPlan] = useState<boolean>();
  const [isHotmartUserRedirectionModalOpen, setIsHotmartUserRedirectionModalOpen] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<string>();
  const canceledPlan = dataUser.suscription?.isCancellationScheduled ?? dataUser.suscription.isCanceled ?? false;
  const expirationDate = dataUser.suscription.expired_time;
  const subscriptionExpirationDate = dataUser.suscription?.expired_time.split("T",1)[0]
  const userBelongsToPayTool = dataUser.existPaytoolLogic === DatabaseStates.ACTIVE
  const belongsToHotmart = !userBelongsToPayTool
  const isYearlySubscription = dataUser.suscription.typeSuscription === 'Anual'
  const isMonthlySubscription = dataUser.suscription.typeSuscription === 'Mensual'
  const currentAddonSubscription = dataUser.addonsInfo.emailContacts.find((element) => element.state === DatabaseStates.ACTIVE)

  function closeHotmartUserRedirectionModal() {
    setIsHotmartUserRedirectionModalOpen(false)
  }

  async function handleSubscriptionCancellation() {
    if (belongsToHotmart) {
      return showUnsubscribe()
    }
    try {
      const isDowngradeScheduled = dataUser.suscription?.isDowngradeScheduled ?? false
      const isCancellationScheduled = dataUser.suscription?.isCancellationScheduled ?? false
      const alreadyHasLitePlanOrIsTrial = dataUser.suscription.name === 'Lite' || dataUser.suscription.name === 'Trial'

      if(isCancellationScheduled) {
        return toastService.warning("¡Ya está programado la cancelación del plan!")
      }
      if (alreadyHasLitePlanOrIsTrial || !isMonthlySubscription) {
        return showUnsubscribe()
      }      
      if (isDowngradeScheduled) {
        showUnsubscribe()
        setTimeout(() => {
          toastService.warning("¡Ya está programado la disminución del plan!")
        }, 3000)
        return
      }
      if (isMonthlySubscription) {
        return showDowngrade()
      }
    }catch (error) {
      console.error("Error validating downgrade schedule", error)
      toastService.error("Error validando la programación de la disminución del plan")
    }
  }

  function handleChangePlan() {
    if (belongsToHotmart) return setIsHotmartUserRedirectionModalOpen(true);
    dispatcher(resetCheckoutState())
    showPlans()
  }

  useEffect(() => {
    setDisabledPlan(Boolean(plansService.checkPlanExpired(expirationDate)));
    setCurrentPlan(dataUser.suscription.limitsTool);
    setSubscription(dataUser.suscription.name);

    dataUser.suscription.name === "Pioneros"
      ? setContactsAmount("100")
      : setContactsAmount(
          dataUser.suscription.limitsTool.emailMarketingSuscribers.value
        );
  }, [dataUser]);

  return (
    <>
      <Container display={"grid"} gap={"1rem"} gridTemplateColumns={"1fr"} maxWidth={"100%"} responsiveLg={"grid-template-columns: 1fr 60%"}>
        <ContainerItem>
          <Card
              backgroundColor={darkMode ? "#0d0d0d" : "white"}
              padding={"1rem 2rem 2rem"}
          >
            <CustomTitle color={"black"} fontWeight={"600"} fontSize={"18px"} textAlign={"start"} margin={"0"}>Suscripción principal</CustomTitle>
            <SubscriptionCard darkMode={darkMode}>
              <Container flexDirection="column">
                <CardBannerContainer>
                  <CardBanner
                      width="100px"
                      src={`${
                          config.urls.res
                      }/platform/new_banners/banner_${subscription?.toLowerCase()}.png`}
                      alt=""
                  />
                  <PlanName fontSize="40px" top="50%">
                    {subscription}
                  </PlanName>
                  <PlanName fontSize="13px" top="70%">
                    {dataUser.suscription.typeSuscription}
                  </PlanName>

                  <DateSubscriptionInfo
                      expired={disabledPlan}
                      canceledPlan={canceledPlan}
                      expirationDate={subscriptionExpirationDate}
                      subtractDays={true}
                  />
                  <Container
                      margin="0 2rem"
                      gap="1rem"
                      responsive="flex-direction: row;"
                      position={"absolute"}
                      bottom={"-25px"}
                      right={"0"}
                      left={"0"}
                      backgroundColor={"white"}
                      borderRadius={"30px"}
                      justifyContent={"center"}
                      width={"auto"}
                      padding={".5rem 2rem"}
                      boxShadow={globalstyles.boxShadows.normal}
                  >
                    {ICONS.map((icon, index) => {
                      const planName = dataUser.suscription.name;
                      const planHasEmail =  !((planName === "Basic" || planName === "Trial" || planName === "Lite") && icon === "emailtool")
                      if(currentAddonSubscription || planHasEmail) {
                        return (
                            <ContainerItem
                                key={`subscription-icon-${index}`}
                                width="unset"
                                responsive="width: unset;"
                            >
                              <ToolImg
                                  src={`${config.urls.res}/platform/icons/PNG/Icono-${icon}.png`}
                              />
                            </ContainerItem>
                        )
                      }
                    })}
                  </Container>
                </CardBannerContainer>
                <CardBannerDescription>
                  <Container
                    alignItems="flex-start"
                    display="flex"
                    flexDirection="column"
                    margin="2rem 1rem 1rem"
                  >
                    <CustomTitle textAlign={"start"} color={"black"} fontWeight={"500"} fontSize={"16px"} margin={"0"}>Herramientas Incluidas:</CustomTitle>
                    <PlanDescription alignItems={"flex-start"} width={"100%"}>
                      {currentPlan ? (
                          <PlanListItems currentPlan={currentPlan} />
                      ) : null}
                    </PlanDescription>

                    <Container justifyContent={"center"} alignItems={"center"} gap={"1rem"}>
                      <ConfirmButton
                        onClick={handleChangePlan}
                        style={{
                          display: `${isMonthlySubscription || isYearlySubscription ? "block" : "none"}`,
                        }}
                      >
                        {disabledPlan ? 'Renovar / Cambiar plan' : 'Cambiar plan'}
                      </ConfirmButton>
                      {
                        disabledPlan && (isMonthlySubscription || isYearlySubscription) &&
                        <ExplainToolTip>
                          Cuando presiones el botón de <strong>"Renovar / Cambiar plan"</strong> podrás renovar tu plan con el mismo plan anterior o seleccionar uno nuevo
                        </ExplainToolTip>
                      }
                      {dataUser.cancelRequest === 0 && !canceledPlan && (
                          <Unsubscribe
                              onClick={handleSubscriptionCancellation}
                              style={{
                                display: `${!disabledPlan ? "block" : "none"}`,
                              }}
                          >
                            Cancelar suscripción
                          </Unsubscribe>
                      )}
                    </Container>
                  </Container>
                </CardBannerDescription>
              </Container>
            </SubscriptionCard>
          </Card>
        </ContainerItem>
        <Container flexDirection={"column"} gap={"1rem"}>
          <Card
              backgroundColor={darkMode ? "#0d0d0d" : "white"}
              padding={"10px 2rem 2rem"}
          >
            <CustomTitle color={"black"} fontWeight={"600"} fontSize={"18px"} textAlign={"start"} margin={"5px 0 10px"}>Herramientas complementarias</CustomTitle>
            <ToolCard showUnsubscribe={showUnsubscribe} showPlans={showPlans}/>
          </Card>
          <Card
              backgroundColor={darkMode ? "#0d0d0d" : "white"}
              padding={"1rem 2rem 2rem"}
          >
            <Container flexDirection={"column"} alignItems={"center"}>
              <DowngradePictureStyled src={`${config.urls.res}/platform/mastertools-avatar/its-comming.png`} width={"250px"} height={"200px"} />
              <CustomTitle color={"black"} fontWeight={"700"} fontSize={"18px"} textAlign={"center"}>Próximamente aquí encontrarás paquetes complementarios</CustomTitle>
            </Container>
          </Card>
        </Container>
      </Container>
      <ChatRedirectModal closeModal={closeHotmartUserRedirectionModal} isOpen={isHotmartUserRedirectionModalOpen} />
    </>
  );
};
