import {Container} from "../../../styles/globalcomponents";
import {SubscriptionPanelOverview} from "./overview/subscriptionPanelOverview.component";
import {PreUnsubscribe} from "./unsubscribe/PreUnsubscribe.component";
import {PlansService} from "../../../services/plansService";
import {Unsubscribe} from "./unsubscribe/unsubscribe.component";
import {Plan} from "../../../interfaces/plans";
import {useReducer} from "react";
import {State, SubscriptionTabReducer} from "./SubscriptionTabReducer";
import {PlansOverview} from "./plan/tabs/planSelectionTab/plans.component";
import {PlansCheckout} from "./plan/plansCheckout.component";

export const TAB_INITIAL_STATE: State = {
    showOverview: true,
    showDowngrade: false,
    showUnSubscribe: false,
    showPlans: false,
    isEmailCancellation: false,
    onlyAdditionalTools: false
}

const plansService = new PlansService();
export function SubscriptionTab() {
    const [state, dispatch] = useReducer(SubscriptionTabReducer, TAB_INITIAL_STATE)

    function handleShowUnsubscribe(isEmailCancellation: boolean = false) {
        dispatch({type: "showUnsubscribe", payload: {isEmailCancellation, onlyAdditionalTools: false}})
    }

    function handleShowDowngrade() {
        dispatch({type: "showDowngrade"})
    }
    function handleShowPlans(onlyAdditionalTools: boolean = false) {
        dispatch({type: "showPlans"})
    }

    function handleShowSubscriptionsPanel() {
        dispatch({type: "showOverview"})
    }

    return (
        <>
            <Container>
                {state.showOverview && <SubscriptionPanelOverview showPlans={handleShowPlans} showDowngrade={handleShowDowngrade} showUnsubscribe={handleShowUnsubscribe} />}
                {state.showDowngrade && <PreUnsubscribe onAbortCancellation={handleShowSubscriptionsPanel} onStartCancellation={handleShowUnsubscribe} />}
                {state.showUnSubscribe && <Unsubscribe isEmailCancellation={state.isEmailCancellation} onAbortCancellation={handleShowSubscriptionsPanel}/>}
                {state.showPlans && <PlansCheckout onAbortCancellation={handleShowSubscriptionsPanel}/>}
            </Container>
        </>
    )
}

export async function validateIfDowngradeIsAlreadySchedule(plans: Plan[], userEmail: string) {
    const currentPlan = plans.find(plan => plan.suscriptionInfo.name === "Lite")
    if(!currentPlan) throw new Error()
    const litePlanCode = currentPlan.suscriptionInfo.codePlanPaytool
    return await plansService.validateIfDowngradeIsAlreadySchedule(userEmail, litePlanCode, 395)
}
