import {
    ProgressBarContainer,
    ProgressBarLine,
    ProgressBarNumberIndicator,
    ProgressBarWrapper
} from "./progressBar.styles";
import {getFormattedNumber} from "../../lib/numberFormat";

interface ProgressBarProps {
    className?: string
    currentValue: number
    total: number
    label?: {
        text: string,
        position: 'top' | 'bottom'
    },
    showNumberIndicator: boolean
}

export function ProgressBar({currentValue, total, label, showNumberIndicator, className = ""}: ProgressBarProps) {
    const percentage = currentValue * 100 / total
    const labelIsOnTop = label && label.position === 'top'

    return (
        <ProgressBarContainer className={className}>
            {labelIsOnTop &&
              <div>
                <small>{label?.text}</small>
              </div>
            }
            <ProgressBarWrapper>
                <ProgressBarLine percentage={percentage}/>
            </ProgressBarWrapper>
            {
                showNumberIndicator
                &&
                <ProgressBarNumberIndicator>
                    {getFormattedNumber(currentValue)} / {getFormattedNumber(total)} (<strong>{percentage}%</strong>)
                </ProgressBarNumberIndicator>
            }
            {!labelIsOnTop && <small>{label?.text}</small>}
        </ProgressBarContainer>
    )
}
