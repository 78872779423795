import React, {useState, useEffect, useRef} from "react";

interface Props {
    endTimeInSeconds: number,
    onCompleted: () => void,
    label: string
}

export const Countdown = ({ endTimeInSeconds, onCompleted, label }: Props) => {
    const [timeLeft, setTimeLeft] = useState<number>(endTimeInSeconds);
    const timerCount = useRef<number>(endTimeInSeconds)
    const timerId = useRef<number>(0)

    useEffect(() => {
        timerId.current = window.setInterval(() => {
            setTimeLeft(prev => (prev - 1));
            timerCount.current--
            if(timerCount.current <= 0) {
                clearInterval(timerId.current)
                onCompleted()
            }
        }, 1000);

        return () => clearInterval(timerId.current);
    }, [endTimeInSeconds, onCompleted]);

    return (
        <>
            <p>{label} {timeLeft}</p>
        </>
    );
};
