import {
    FormCard,
    FormTitle,
    InputField,
} from "./unsubscribe.styles";
import {UnsubscribeFormQuestions} from "./questions/questions.component";
import {Container, ContainerItem} from "../../../../styles/globalcomponents";
import {UnsubscribeReturningNumber} from "./numbers/numbers.component";
import type {UnsubscribeFormState} from "./unsubscribe.component";
import {Checkbox} from "../../../../components/checkbox/checkbox.component";

interface UnsubscribeFormProps {
    setFormData: (newState: any) => void
    formState: UnsubscribeFormState
    isEmailCancellation: boolean
}

export function UnsubscribeForm({setFormData, formState, isEmailCancellation}: UnsubscribeFormProps) {
    return (
        <>
            <FormCard>
                <FormTitle>
                    ¿Por qué estás cancelando tu suscripción de MasterTools?
                </FormTitle>
                <UnsubscribeFormQuestions setFormData={setFormData}/>
            </FormCard>
            <FormCard>
                <FormTitle>¿Vas a migrar hacia otra herramienta?</FormTitle>
                <Container flexDirection={"column"} gap={"1rem"}>
                    <ContainerItem>
                        <Checkbox label={"Si."} onClick={() => setFormData({migration: true})} checked={formState.migration}/>
                    </ContainerItem>
                    <ContainerItem>
                        <Checkbox label={"No."} onClick={() => setFormData({migration: false})} checked={!formState.migration}/>
                    </ContainerItem>
                </Container>
            </FormCard>
            <FormCard>
                <FormTitle>¿Qué tan probable es que regreses en el futuro?</FormTitle>
                <UnsubscribeReturningNumber setForm={setFormData} form={formState}/>
            </FormCard>
            <FormCard>
                <FormTitle>
                    ¿Te gustaría compartir algo más sobre tu experiencia?
                </FormTitle>
                <InputField
                    onChange={({target}) =>{
                        setFormData({observations: target.value})
                    }}
                    placeholder="Comparte tu experiencia"
                    value={formState.observations}
                />
            </FormCard>
            <Container width="80%">
                <Checkbox
                    checked={formState.understanding}
                    onClick={() =>
                        setFormData({ understanding: !formState.understanding })
                    }
                    label={
                        isEmailCancellation
                            ? "Entiendo que perderé todos los contactos creados, automatizaciones, campañas y toda la relación que has construido con tus suscriptores"
                            : "Entiendo que perderé todos mis funnels creados, dominios y subdominios conectados, los clientes, links acortados, leads y todos los copys generados"
                    }
                />
            </Container>
        </>
    )
}
