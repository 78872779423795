import {Range} from "react-range";
import {useEffect, useRef, useState} from "react";
import {AdditionalToolRangeContainer, AdditionalToolRangeDotMark} from "../planDescription.styles";
import {globalstyles} from "../../../../../../../styles/animations/globalstyles";
import {AdditionalToolEmailSpecifications, addonsDataMock} from "./addons.data";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/useRedux";
import {addAdditionalTool} from "../../../../../../../redux/slices/checkout";
import {GetCurrentPlan} from "../../../../../../../services/addonService";
import {ToastService} from "../../../../../../../services/toastService";
import { PlanSelectedDowngradeModal } from "../../planSelectionTab/modals/planSelectedDowngrade.modal";
import { DatesLib } from "src/lib/datesLib";

interface AdditionalToolRangeSelectorProps {
}

export enum Direction {
    Right = 'to right',
    Left = 'to left',
    Down = 'to bottom',
    Up = 'to top'
}

export interface ITrackBackground {
    min: number;
    max: number;
    values: number[];
    colors: string[];
    direction?: Direction;
    rtl?: boolean;
}

function getTrackBackground({
                                values,
                                colors,
                                min,
                                max,
                                direction = Direction.Right,
                                rtl = false
                            }: ITrackBackground) {
    if (rtl && direction === Direction.Right) {
        direction = Direction.Left;
    } else if (rtl && Direction.Left) {
        direction = Direction.Right;
    }
    // sort values ascending
    const progress = values.slice(0).sort((a, b) => a - b).map(value => ((value - min) / (max - min)) * 100);
    const middle = progress.reduce(
        (acc, point, index) =>
            `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
        ''
    );
    return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${colors[colors.length - 1]
    } 100%)`;
}

const STEP = 1;
const MIN = 0;
const MAX = 9;
const ACTIVE_STATE = 1
const DEBOUNCE_TIME_MS = 500

const toastService = new ToastService()
const dateManager = new DatesLib()

export function AdditionalToolRangeSelector({}: AdditionalToolRangeSelectorProps) {
    const dispatcher = useAppDispatch()
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const currentAddonPlan = dataUser.addonsInfo.emailContacts.find((element) => element.state === ACTIVE_STATE)
    const currentAddonPlanInfo = GetCurrentPlan(currentAddonPlan?.quantity ?? 0)
    const currentAddonPlanInfoIndex = addonsDataMock.findIndex(element => element.id === currentAddonPlanInfo.id)
    const additionalTools = useAppSelector((state) => state.checkout.additionalTools)
    const additionalToolEmail = additionalTools.find((tool) => tool.name === 'EmailTool')
    const initialValue = currentAddonPlanInfoIndex ?? additionalToolEmail?.index ?? 0
    const [values, setValues] = useState([initialValue]);
    const [isPlanSelectedDowngradeModalOpen, setIsPlanSelectedDowngradeModalOpen] = useState<boolean>(false);
    const debounceRef = useRef<NodeJS.Timeout>()

    function closePlanSelectedDowngrade() {
        setIsPlanSelectedDowngradeModalOpen(false);
    }


    function handleChangeValue(index: number) {
        const selectedAddonsInfo = addonsDataMock.find((item) => item.id === index) ?? addonsDataMock[0]
        if(currentAddonPlan) {
            const selectedQuantityIsLowerThanCurrent = selectedAddonsInfo.contacts < currentAddonPlan?.quantity
            const addonsPlanIsExpired = dateManager.isDateExpired(currentAddonPlan.expired_time)

            if (addonsPlanIsExpired && selectedQuantityIsLowerThanCurrent) {
                return setIsPlanSelectedDowngradeModalOpen(true)
            }
            if (
              selectedQuantityIsLowerThanCurrent &&
              currentAddonPlan?.isDowngradeScheduled
            ) {
              if (debounceRef.current) {
                clearTimeout(debounceRef.current);
              }

              debounceRef.current = setTimeout(() => {
                toastService.warning(
                  "Ya está programada una disminución del plan"
                );
              }, DEBOUNCE_TIME_MS);
              return;
            }
        }

        dispatcher(addAdditionalTool({
            index: selectedAddonsInfo.id,
            name: "EmailTool",
            quantity: selectedAddonsInfo.contacts,
            price: selectedAddonsInfo.price,
            code: selectedAddonsInfo.code,
            addonPlanName: selectedAddonsInfo.name,
            specifications: AdditionalToolEmailSpecifications
        }))
        setValues([index])
        return
    }

    useEffect(() => {
        setValues([additionalToolEmail?.index ?? 0])
    }, [additionalToolEmail?.index])

    return (
        <>
            <AdditionalToolRangeContainer>
                <Range
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => handleChangeValue(values[0])}
                    renderMark={({props, index}) => (
                        <AdditionalToolRangeDotMark
                            {...props}
                            isCurrent={index * STEP === currentAddonPlanInfoIndex}
                            style={{
                                ...props.style,
                                height: index * STEP === currentAddonPlanInfoIndex ?'1rem' : '.7rem',
                                width: index * STEP === currentAddonPlanInfoIndex ? '1rem' : '.7rem',
                                transform: index * STEP === currentAddonPlanInfoIndex ? 'translateY(-.05rem)' : 'none',
                                borderRadius: "50%",
                                backgroundColor: index * STEP === currentAddonPlanInfoIndex ? globalstyles.colors.primary : index * STEP < values[0] ? globalstyles.colors.darkenGreen : '#ccc'
                            }}
                        />
                    )}
                    renderTrack={({props, children}) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '20px',
                                display: 'flex',
                                borderRadius: '30px',
                                width: '100%',
                                backgroundColor: globalstyles.colors.grayLighter
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '5px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values,
                                        colors: [globalstyles.colors.secondary, '#ADADAD'],
                                        min: MIN,
                                        max: MAX
                                    }),
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({props, isDragged}) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '1.5rem',
                                width: '1.5rem',
                                border: isDragged ? `1px solid ${globalstyles.colors.primary}` : `1px solid ${globalstyles.colors.secondary}`,
                                borderRadius: '50%',
                                backgroundColor: '#FFF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 2px 6px 10pxs #AAA',
                            }}
                        >
                            <div
                                style={{
                                    height: '.5rem',
                                    width: '.5rem',
                                    borderRadius: '50%',
                                    backgroundColor: isDragged ? globalstyles.colors.primary : '#CCC'
                                }}
                            />
                        </div>
                    )}
                />
                {/*<output style={{marginTop: '30px'}}>{values[0].toFixed(1)}</output>*/}
            </AdditionalToolRangeContainer>
            <PlanSelectedDowngradeModal isOpen={isPlanSelectedDowngradeModalOpen} closeModal={closePlanSelectedDowngrade} />
        </>
    )
}
