import {
    PlanCardStyled,
    PlanDescription,
    PlanHeader,
    PlanPrice, PlanPriceNumber,
    PlanTitle,
    PlanTool,
    PlanToolsContainer
} from "./plans.styles";
import {config} from "../../../../../../config";
import {Fragment, useState} from "react";
import {Plan} from "../../../../../../interfaces/plans";
import {PLANS_BANNERS, TOOLS_ICONS} from "../plans.data";
import {PlanDescriptionModal} from "./modals/planDescription.modal";
import {ConfirmButton} from "../../../../../../styles/globalcomponents";

interface PlanCardProps {
    active?: boolean
    plan: Plan
    isAnualMembership?: boolean
    onUserSubscription: (plan: Plan) => void
}

export function PlanCard({active, plan, isAnualMembership, onUserSubscription}: PlanCardProps) {
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false)
    const subscriptionName = plan.suscriptionInfo.name
    const haveLitePlan =  subscriptionName === "Lite"
    const haveBasicOrLitePlan = haveLitePlan || subscriptionName === "Basic";
    const backgroundImage = PLANS_BANNERS[subscriptionName.toLowerCase()] ?? PLANS_BANNERS[0]

    function closeDescriptionModal() {
        setIsDescriptionModalOpen(false)
    }

    return (
        <>
            <PlanCardStyled
                active={active}
            >
                <PlanHeader backgroundImage={backgroundImage}>
                    <PlanTitle>
                        Plan {subscriptionName}
                    </PlanTitle>
                </PlanHeader>
                <PlanDescription>
                    <PlanToolsContainer>
                        {TOOLS_ICONS.map((toolIcon, index) => {
                            const isEmailToolImg = toolIcon === `${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`
                            const showEmailToolDependOnPlan = haveBasicOrLitePlan && isEmailToolImg
                            if (showEmailToolDependOnPlan) {
                                return <Fragment key={`tools-${index}`}></Fragment>;
                            } else {
                                return <PlanTool src={toolIcon} key={`tools-${index}`}></PlanTool>;
                            }
                        })}
                    </PlanToolsContainer>
                    <PlanPrice>
                        <PlanPriceNumber>
                            ${isAnualMembership ? plan.suscriptionInfo.anualPrice : plan.suscriptionInfo.monthPrice} {isAnualMembership ? "USD / año" : "USD / mes"}
                        </PlanPriceNumber>
                    </PlanPrice>
                    <ConfirmButton
                        disabled={active}
                        onClick={() => onUserSubscription(plan)}
                    >
                        {active ? "Plan actual" : "Seleccionar plan"}
                    </ConfirmButton>
                    <button className={"link"} onClick={() => setIsDescriptionModalOpen(true)}>
                        Ver descripción del plan
                    </button>
                </PlanDescription>
            </PlanCardStyled>
            <PlanDescriptionModal planName={subscriptionName} isOpen={isDescriptionModalOpen} closeModal={closeDescriptionModal}/>
        </>
    )
}
