import { instance, subsInstance } from "../axios/axiosConfig";
import axios from "axios";
import { config } from "../config";
import {
  GetSubscriptionStateResponse,
  Plan, PlanFrequency,
  PlanResponse,
  SubscriptionInfo,
  UserSubscriptionStateResponse,
} from "../interfaces/plans";
import {addDays, DatesLib} from "src/lib/datesLib";
import { DataUser } from "src/interfaces/redux";
import {numberFormat} from "../lib/numberFormat";
import {LocalStorageKeys} from "../redux/localStorageKeys";
import {verifyIsInvalidPlan} from "../pages/profile/subscription/helpers";

interface GetUserSubscriptionState {
  productId: number,
  newPlanInfo: SubscriptionInfo,
  newPlanPayFrequency: PlanFrequency
}

export const PRODUCT_IDS = {
  MasterTools: 395,
  EmailTool: 402
}

export class PlansService {
  datesLib = new DatesLib();

  checkPlanExpired(expirationDate: string) {
    return this.datesLib.isDateExpired(expirationDate);
  }

  async handleSubscriptions(
      dataUser: DataUser,
      expiredPlan: boolean,
      plan: Plan,
      plans: Plan[],
      payFrequency: string
  ) {
    const planName = plan.suscriptionInfo.name;
    const currentPlanName = dataUser.suscription.name
    const isANewPlan = planName !== currentPlanName
    const isExpiredPlan = verifyIsInvalidPlan(dataUser.suscription.expired_time, dataUser.stateUser)

    if (isANewPlan && isExpiredPlan) {
      const planToChange = plans.find((plan) => planName === currentPlanName);

      if (!planToChange) {
        throw new Error("Plan doesn't exists")
      }

      return await this.getUserSubscriptionState(
          dataUser.email,
          395,
          planToChange.suscriptionInfo,
          currentPlanName,
          payFrequency
      );
    }

    return await this.getUserSubscriptionState(
        dataUser.email,
        395,
        plan.suscriptionInfo,
        currentPlanName,
        payFrequency
    );
  }

  async unsubscribe(form: any) {
    const response = await axios.post(
        `${config.axios.admUrl}/api/mastertoolsCancelations/insertCancelation`,
        form
    );
    return response.data;
  }

  async unsubscribeByPaytool(userData: any) {
    const response = await instance.post("/app/paytool/cancel/client/", {
      emailUser: userData.email,
      product: userData.product,
    });
    return response.data.data;
  }

  async detailSubscription() {
    const response = await instance.get("/app/suscription/detailSuscription/");
    return response.data.data;
  }

  async getUserSubscriptionStateNewest({productId, newPlanInfo, newPlanPayFrequency}: GetUserSubscriptionState) {
    const dataUser = this.getDataUser();
    const email = dataUser.email
    const newPlan = newPlanInfo
    const currentPlanName = dataUser.suscription.name
    const codePlans: any = {
      'Anual': newPlanInfo.codePlanAnualPaytool,
      'Mensual': newPlanInfo.codePlanPaytool
    }
    const planCode = codePlans[newPlanPayFrequency]
    if(!planCode) throw new Error("Plan frequency not found")

    const validationResponse = await subsInstance.get(`suscription/validation/${email}/${planCode}/${productId}`);
    if(validationResponse.data.codeResponse !== 200) throw new Error("Error validating subscription")

    const validationData = (validationResponse.data as UserSubscriptionStateResponse).data
    const nextPayment = validationData.nextPayment?.split("T", 1)

    const planData: GetSubscriptionStateResponse = {
      isApportionmentPrice: false,
      apportionmentPrice: undefined,
      typeChange: validationData.typeChange || "Upgrade",
      nextPayment: validationData.nextPayment ? String(nextPayment) : undefined,
      priceTotal: undefined,
      codePlanPaytool: planCode,
      valueToCharge: undefined,
    };

    if (!validationData.typeChange) throw new Error("typeChange is undefined, validation failed")

    if (validationData.typeChange === "Upgrade") {
      if (validationData.apportionmentPrice) {
        planData.isApportionmentPrice = true;
        planData.priceTotal = validationData.priceTotal;
        planData.apportionmentPrice = validationData.apportionmentPrice;
        return planData;
      }
      if (currentPlanName !== newPlan.name) {
        planData.priceTotal = validationData.priceTotal;
        return planData;
      }
      return planData;
    }

    if (validationData.typeChange === "Renewal") {
      const nextPaymentDate = String(addDays(new Date(), 30))
      planData.priceTotal = validationData.priceTotal;
      planData.nextPayment = nextPaymentDate;
      return planData;
    }

    if (!validationData.typeChange || validationData.typeChange === "Downgrade") {
      const nextPayment = validationData.nextPayment?.split("T", 1)
      planData.priceTotal = validationData.priceTotal;
      planData.nextPayment = String(nextPayment);
      planData.typeChange = "Downgrade";
      return planData;
    }
  }

  async getUserSubscriptionState(
      clientEmail: string,
      productId: number,
      newPlan: SubscriptionInfo,
      currentPlanName: string,
      payFrequency: string
  ) {
    let planCode = payFrequency === "Mensual" ? newPlan.codePlanPaytool : newPlan.codePlanAnualPaytool;
    const data = await subsInstance.get(`suscription/validation/${clientEmail}/${planCode}/${productId}`);
    const nextPayment = new Date();
    nextPayment.setDate(nextPayment.getDate() + 30);
    const response: UserSubscriptionStateResponse = data.data;

    let objResponse: PlanResponse = {
      apportionmentPrice: undefined,
      title: "",
      message: "",
      typeChange: response.data.typeChange || "Upgrade",
      urlCheckout: undefined,
      nextPayment: undefined,
      priceTotal: undefined,
      codePlanPaytool: planCode,
      valueToCharge: undefined,
    };

    if (!response.data.typeChange) {
      throw new Error("typeChange is undefined, validation failed")
    }

    if (response.data.typeChange === "Upgrade") {
      if (!response.data.isChangePlan) {
        objResponse.urlCheckout = response.data.url_checkout;
        return objResponse;
      }
      if (response.data.apportionmentPrice) {
        const apportionmentPriceFormatted = numberFormat.format(response.data.apportionmentPrice)
        const totalPriceFormatted = numberFormat.format(response.data.priceTotal ?? 0)

        objResponse.title = "Uff! Qué buena elección!";
        objResponse.message = [
          `Lo nuestro continúa, pero mejor que antes ;) Adiós a las limitaciones del plan  ${currentPlanName} y hola a los beneficios del plan ${newPlan.name} cuando toques el botón.`,
          `Cobraremos ${apportionmentPriceFormatted} USD  a tu tarjeta por el uso de tu nuevo plan durante los días antes del próximo cobro: ${nextPayment.toLocaleDateString()}.`,
          `Y a partir del próximo cobro pagarías ${totalPriceFormatted} USD por tu plan ${newPlan.name}.`,
          'Solo toca el botón para confirmar tu nueva elección!'
        ];
        objResponse.priceTotal = response.data.priceTotal;
        objResponse.nextPayment = response.data.nextPayment;
        objResponse.apportionmentPrice = response.data.apportionmentPrice;
        return objResponse;
      }
      if (currentPlanName !== newPlan.name) {
        objResponse.title = "Uff! Qué buena elección!";
        objResponse.message = `Tooler, tu nuevo plan de contactos será actualizado INMEDIATAMENTE, cambiaremos tu plan de ${currentPlanName} a ${newPlan.name}.\n
        Realizaremos un cobro de: ${response.data.priceTotal} USD en tu tarjeta que corresponde al uso de tu nuevo plan para los días que restan hasta tu próximo ciclo de facturación: ${nextPayment.toLocaleDateString()}.\n
        A partir del próximo ciclo, el valor a pagar por tu plan ${newPlan.name} será de: ${response.data.priceTotal}USD.\n
        Toca en el botón para confirmar la actualización inmediata de tu plan.
        `;
        objResponse.priceTotal = response.data.priceTotal;
        return objResponse;
      }
      if (currentPlanName === newPlan.name) {
      }
      return objResponse;
    }

    if (response.data.typeChange === "Renewal") {
      const totalPriceFormatted = numberFormat.format(response.data.priceTotal ?? 0)
      const nextPaymentDate = response.data.nextPayment?.split("T", 1)
      objResponse.title = "¡Ya comenzábamos a extrañarte!";
      objResponse.message = [
        `En toda relación debe haber puntos claros y estos son los nuestros! A partir de ahora tu suscripción al plan ${currentPlanName} quedará activa, cobraremos ${totalPriceFormatted} USD a tu tarjeta y no volveremos a cobrar hasta ${nextPaymentDate}`,
        '¡Toca el botón para confirmar tu regreso!'
      ];
      objResponse.priceTotal = response.data.priceTotal;
      return objResponse;
    }

    if (!response.data.typeChange || response.data.typeChange === "Downgrade") {
      const totalToPay = response.data.priceTotal
      const nextPayment = response.data.nextPayment?.split("T", 1)
      objResponse.title = "¿Listo para utilizar MasterTools al menor costo?";
      objResponse.message = `Nada mejor que acceder a esos descuentos que nadie más conoce! Pagando solo ${totalToPay}USD en tu próxima fecha de cobro ${nextPayment}, te pasaremos del plan ${currentPlanName} a tu nuevo plan LITE.`;
      objResponse.priceTotal = response.data.priceTotal;
      objResponse.nextPayment = String(nextPayment);
      objResponse.typeChange = "Downgrade";
      return objResponse;
    }
    return objResponse;
  }

  async handlePayment(
      email: string,
      codePlanToChange: string,
      codeProduct: number,
      typeChange: string,
      valueToCharge: number
  ) {
    const response = await subsInstance.post("suscription/changeplan/", {
      email,
      codePlanToChange,
      codeProduct,
      typeChange,
      valueToCharge,
    });

    return response.data;
  }

  async validateIfDowngradeIsAlreadySchedule(email: string, planCode: string, productId: number):Promise<boolean> {
    const response = await subsInstance.get(`suscription/validate-schedule-downgrade/${email}/${planCode}/${String(productId)}`);
    return response.data.data;
  }

  private getDataUser() {
    const dataUser = JSON.parse(localStorage.getItem(LocalStorageKeys.userLoggedInfo) ?? '').dataUser as DataUser
    if (!dataUser) throw new Error("User not found")
    return dataUser;
  }
}
