import {BackButtonStyled} from "../../../../components/ui/backButton/index.styles";
import {LeftArrowIcon} from "../../../../components/icons";
import {Card, Container, ContainerItem, CustomTitle} from "../../../../styles/globalcomponents";
import {PlansTabContainer} from "./planTabContainer/PlanTabContainer.component";
import {PlansSelectionTab} from "./tabs/planSelectionTab/plansSelectionTab.component";
import {PlansDescriptionTab} from "./tabs/planDescriptionTab/plansDescriptionTab.component";
import {PaymentMethodTab} from "./tabs/paymentMethodTab/paymentMethodTab.component";
import {useAppSelector} from "../../../../hooks/useRedux";
import {PlansCheckoutSummary} from "./summary/plansCheckoutSummary.component";

interface PlansCheckoutProps {
    onAbortCancellation: () => void
}

export function PlansCheckout({onAbortCancellation}: PlansCheckoutProps) {
    const selectedPlan = useAppSelector((state) => state.checkout.selectedPlan)
    const payOnlyAdditionalTools = useAppSelector((state) => state.checkout.payOnlyAdditionalTools)
    const currentStep = useAppSelector((state) => state.checkout.currentStep)
    const isNotFirstStep = currentStep !== 1
    const showPurchaseSummary = payOnlyAdditionalTools || (selectedPlan !== undefined && isNotFirstStep)

    const tabsTitles = [
        'Selecciona el plan',
        'Descripción del plan',
        'Método de pago',
    ];

    const tabsContent = [
        <PlansSelectionTab />,
        <PlansDescriptionTab />,
        <PaymentMethodTab />,
    ];

    return (
        <Container
            display={"grid"}
            gap={"1rem"}
            gridTemplateColumns={"1fr"}
            responsiveLg={`grid-template-columns: ${showPurchaseSummary ? "1fr 33%" : "1fr"}`}
        >
            <ContainerItem>
                <Card
                    backgroundColor={"white"}
                    padding={"0 2.5rem 2rem"}
                    responsive={"0 2.5rem 2rem"}
                    position={"relative"}
                >
                    <Container
                        margin={"auto"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        padding={"3rem 0 0 "}
                        responsiveLg={"padding: 1.5rem;"}
                    >
                        <BackButtonStyled secondary={true} onClick={onAbortCancellation}>
                            <LeftArrowIcon fill={"white"} />
                        </BackButtonStyled>
                        <CustomTitle margin={"0.5rem 0"} color={"black"} fontWeight={"600"} fontSize={"18px"}>Actualiza tu plan MasterTools</CustomTitle>
                        <PlansTabContainer titles={tabsTitles} contents={tabsContent} />
                    </Container>
                </Card>
            </ContainerItem>
            {
                showPurchaseSummary ? (
                    <PlansCheckoutSummary/>
                ) : null
            }
        </Container>
    )
}
