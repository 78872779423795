import { Checkbox } from "../../../../../components/checkbox/checkbox.component";
import { Container } from "../../../../../styles/globalcomponents";
import { UnsubscribeFormState } from "../unsubscribe.component";
import { data } from "./data";

type props = {
  form: UnsubscribeFormState;
  setForm: (newState: any) => void
};

export const UnsubscribeReturningNumber = ({ setForm, form }: props) => {
  return (
    <Container gap={"1rem"}>
      {data.map((item) => (
        <Checkbox
          onClick={() => setForm({reEntry: item,})}
          checked={form.reEntry === item}
          label={`${item}`}
        />
      ))}
    </Container>
  );
};
