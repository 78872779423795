import {instance, subsInstance} from "../axios/axiosConfig";
import {addonsDataMock} from "../pages/profile/subscription/plan/tabs/planDescriptionTab/additionalTool/addons.data";
import {
  EmailToolAccountResponse,
  GetSubscriptionStateResponse,
  UserSubscriptionStateResponse
} from "../interfaces/plans";
import {addDays} from "../lib/datesLib";
import axios from "axios";
import {config} from "../config";

export async function ExecuteActivationSubProductPayment(
    {email, codePlan, idProduct, amount}: { email: string, codePlan: string, idProduct: number, amount: number }
) {
  const response = await subsInstance.post(`suscription/subproduct/approved`, {
    doc_client: "",
    email,
    code_plan: codePlan,
    id_product: idProduct,
    transaction_info: {
      amount,
      currency_code: "USD",
    },
  });

  return response.data;
}

export async function GetUserEmailToolContactsConsumed(idUser: string) {
  const response = await instance.get(`/app/addons/account-status/${idUser}`,)
  return response.data.data as EmailToolAccountResponse
}

export async function GetUserSubscriptionAddonState(
    {email, planCode, productId}: { email: string, planCode: string, productId: number }
) {
  const validationResponse = await subsInstance.get(`suscription/validation/${email}/${planCode}/${productId}`);
  if(validationResponse.data.codeResponse !== 200) throw new Error("Error validating addons subscription")

  const validationData = (validationResponse.data as UserSubscriptionStateResponse).data
  const nextPayment = validationData.nextPayment?.split("T", 1)

  const planData: GetSubscriptionStateResponse = {
    isActivation: Boolean(validationData.isSubProduct),
    isApportionmentPrice: Boolean(validationData.apportionmentPrice),
    apportionmentPrice: validationData.apportionmentPrice,
    typeChange: validationData.typeChange || "Upgrade",
    nextPayment: String(nextPayment),
    priceTotal: validationData.priceTotal,
    codePlanPaytool: planCode,
    valueToCharge: undefined,
  };

  if(validationData.isSubProduct) return planData
  if (!validationData.typeChange) throw new Error("typeChange is undefined, validation failed")
  if (validationData.typeChange === "Upgrade") {
    if (validationData.isChangePlan) {
      planData.isChangePlan = true
    }
    if (!validationData.isChangePlan && validationData.isSubProduct) {
      planData.isChangePlan = false
    }
    return planData;
  }
  if(validationData.typeChange === 'Renewal') {
    planData.nextPayment = String(addDays(new Date(), 30))
    return planData
  }

  return planData
}

export const GetCurrentPlan = (userContacts: string | number) => {
  const addData = addonsDataMock.filter(
    (addon) => addon.contacts === (typeof userContacts === 'string' ? parseInt(userContacts) : userContacts)
  );

  return addData[0];
};
