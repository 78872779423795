import {AccountStatusBarImgWrapperStyled, AccountStatusBarStyled} from "./accountStatusBar.styles";
import {config} from "../../../config";
import {Card, CustomText, CustomTitle} from "../../../styles/globalcomponents";
import {useAppSelector} from "../../../hooks/useRedux";
import {globalstyles} from "../../../styles/animations/globalstyles";
import {useState} from "react";
import {substractDays} from "../../../lib/datesLib";
import {ChatRedirectModal} from "../../profile/subscription/overview/modals/chatRedirectModal";

interface AccountStatusBarProps {
}


export function AccountStatusBar({}: AccountStatusBarProps) {
    const TRIAL_PLAN_ID = 4
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const isCancellationScheduled = dataUser.suscription.isCancellationScheduled
    const isTrial = dataUser.suscription.idSuscription === TRIAL_PLAN_ID
    const cardColor = isCancellationScheduled ? globalstyles.colors.softDanger : isTrial ? globalstyles.colors.softBlue : 'white'
    const imgSource = getImageSource(isCancellationScheduled, isTrial)
    const title = getTitle(dataUser.nameUser, isCancellationScheduled, isTrial)
    const expirationDate = dataUser.suscription.expired_time.split("T")[0] as string
    const paymentDate = substractDays(new Date(expirationDate), 4)
    const [isUserRedirectionModalOpen, setIsUserRedirectionModalOpen] = useState<boolean>(false)
    const plans = useAppSelector((state) => state.auth.plans);
    const typeSubscription = dataUser.suscription.typeSuscription;
    const text = getText(isCancellationScheduled, isTrial, paymentDate, handleUserRedirection, typeSubscription)        
    console.log("plans>>>>>>>>>>", plans);
    console.log("dataUser>>>>>>>>>>", dataUser);

    function handleUserRedirection() {
        setIsUserRedirectionModalOpen(true)
    }

    function closeUserRedirectionModal() {
        setIsUserRedirectionModalOpen(false)
    }

    return (
      <>
        <AccountStatusBarStyled>
            <AccountStatusBarImgWrapperStyled>
                <img src={imgSource} height={"100%"} width={"100%"} />
            </AccountStatusBarImgWrapperStyled>
            <Card padding={".5rem 1rem"} borderRadius={"10px"} backgroundColor={cardColor}>
                <CustomTitle textAlign={"left"} fontWeight={"800"} fontSize={"15px"} margin={".5rem 0"}>
                    {title}
                </CustomTitle>
                <CustomText fontSize={"13px"}>
                    {text}
                </CustomText>
            </Card>
        </AccountStatusBarStyled>
        <ChatRedirectModal closeModal={closeUserRedirectionModal} isOpen={isUserRedirectionModalOpen} />
      </>
    )
}


function getText(isCancellationScheduled: boolean, isTrial: boolean, expirationDate: string, onRedirect: () => void, typeSubscription: string) {    
    if(isCancellationScheduled) return <>Tu cuenta está programada para expirarse el {expirationDate}, si deseas mantener la cuenta activa <strong className={"underline"}><a onClick={onRedirect}>haz clic AQUÍ</a></strong>.</>
    if(isTrial) return `Tu plan gratuito se renovará el ${expirationDate}, en esta fecha se realizará un cobro de $${typeSubscription === "Mensual" ? 49 : 147}USD a tu tarjeta registrada.`
    return `¿Estas listo para crear, editar y gestionar todo el ecosistema digital de tu negocio?`
}
function getTitle(username: string, isCancellationScheduled: boolean, isTrial: boolean) {
    if(isCancellationScheduled) return `Hey ${username}!`
    if(isTrial) return `Hey ${username}!`
    return `Hola ${username}!`
}
function getImageSource(isCancellationScheduled: boolean, isTrial: boolean) {
    const initialImg = `${config.urls.res}/platform/icons/PNG/accountStatus_inital.png`
    const cancellationScheduledImg = `${config.urls.res}/platform/icons/PNG/accounStatus_cancellationScheduled.png`
    const trialImg = `${config.urls.res}/platform/icons/PNG/accountStatus_trial.png`

    if(isCancellationScheduled) return cancellationScheduledImg
    if(isTrial) return trialImg
    return initialImg
}
